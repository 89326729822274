//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmptyState from '@/components/shared/EmptyState.vue'
export default {
    name: 'Presentation',
    components: { EmptyState },
    data: function () {
        return {
            isCheck: false,
            isLoading: false,
            lang: localStorage.lang,
            total_pages: 0,
            total_rows: 0,
            modelList: [],
            query: {
                Filter: [],
                PageNumber: 1,
                PageSize: process.env.VUE_APP_Default_Page_Size,
            },
            Filter: {},
            yearsList: Array(100).fill().map((_, id) => 2022 - id),
            QuarterList: [],
        };
    },
    methods: {
        search: function () {
            var self = this;
            self.query.Filter = [];
            if (self.Filter.Year && self.Filter.Year.length > 0) {
                self.query.Filter.push({
                    FieldName: "Year",
                    Operation: "in",
                    value: self.Filter.Year.toString(),
                });
            }

            if (self.Filter.Quarter && self.Filter.Quarter.length > 0) {
                self.query.Filter.push({
                    FieldName: "Quarter",
                    Operation: "in",
                    value: self.Filter.Quarter.toString(),
                });
            }

            self.query.PageNumber = 1;
            self.modelList = [];
            self.isCheck = false;
            self.load();
        },

        loadLookups: function () {
            Promise.all([this.loadTypes()]);

        },

        loadTypes: function () {
            var self = this;
            return new Promise(function (resolve, reject) {
                self.axios.get("".concat(process.env.VUE_APP_APIEndPoint, "FinancialStatementType/GetAllCategory"), self.query, self.headers)
                    .then(function (res) {
                        self.FinancialStatementTypesList = res.data.Data;
                        resolve("Success!");
                    })
                    .catch(function (err) {
                        return console.log(err);
                    }).finally(function () {
                        return;
                    });
            });
        },

        load: function () {
            debugger
            let self = this;
            if (!self.isLoading && !self.isCheck) {
                self.isLoading = true;
                self.axios
                    .post(process.env.VUE_APP_APIEndPoint + "Presentation/PublicGetAll", self.query)
                    .then(function (res) {
                        self.modelList = [...self.modelList, ...res.data.Data];
                        self.query.PageNumber = res.data.PageNumber;
                        self.query.PageSize = res.data.PageSize;
                        self.total_rows = res.data.Total;
                        self.total_pages = Math.ceil(self.total_rows / res.data.PageSize);
                        if (self.total_pages > 0 && self.query.PageNumber <= 1) {
                            setTimeout(function () { }, 100);
                        }
                        self.isCheck = false;
                        if (self.total_pages <= self.query.PageNumber) self.isCheck = true;

                    })
                    .finally(() => self.isLoading = false);
            }

        },

        downloadFile: function (link) {
            var self = this;
            if (!link)
                return;

            const anchor = document.createElement('a');
            anchor.href = link;

            document.body.appendChild(anchor);

            anchor.click();

            document.body.removeChild(anchor);

        },
        Scroll: function() {
            let self = this;
            self.query.PageNumber++;
            self.load();
        },

        handleScroll: function(event) {
            let infiniteList = document.getElementById("infinite-list");
            if (infiniteList) {
                let listPosition = infiniteList.offsetTop + infiniteList.offsetHeight;

                if (window.scrollY >= listPosition - 600) {
                    this.Scroll();
                }
            }
        },
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
        var self = this;
        for (let i = 1; i <= 4; i++) {
            self.QuarterList.push({ key: i, text: self.$t('Quarter_' + i) });
        }
        self.QuarterList.push({ key: 5, text: self.$t('Annual') });

    },
    updated() {
        initGallerySlider();
    },
    mounted() {
        let self = this;
        self.load();
        self.loadLookups();
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
